import styled, { css } from 'styled-components';
import { scrollingHorizontalVisible } from '@/styles/utilities';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      gap: 1rem;
      padding: ${theme.spacing.base} ${theme.spacing.xxs};
      justify-content: space-between;

      overflow-x: scroll;

      ${scrollingHorizontalVisible};

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        justify-content: space-evenly;
      }
    `;
  }};
`;

export const ContinentItem = styled.div`
  ${({ theme, ...props }) => {
    return css`
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: ${theme.spacing.md};
        color: ${theme.color.dark};
        padding: 1rem;
        border-radius: 1rem;
        transition: background-color 0.15s ease-in-out;
        &:hover,
        &:focus {
          background: ${theme.color.secondary_200};
          svg {
            max-width: 95%;
          }
        }
      }
    `;
  }};
`;

export const MapContinentWrapper = styled.div`
  ${({ theme, ...props }) => {
    return css`
      border-radius: ${theme.radius.full};
      padding: ${theme.spacing.base};
      background: ${theme.color.secondary_200};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 132px;
      height: 132px;
      svg {
        max-width: 90%;
        color: ${theme.color.secondary_500};
        transition: max-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      }
    `;
  }};
`;
