import React from 'react';
import { ContinentItem, Element, MapContinentWrapper } from './FeatureDestination.styles';
import Link from '@/providers/Link';
import { Text } from '@/components/typography';
import { ContinentMapIcon } from '@/components/icons/ContinentMapIcon';

export interface FeatureDestinationProps {
  data: FeaturedDestinationMapType;
}

const FeatureDestination = ({ data }: FeatureDestinationProps) => {
  return (
    <Element>
      {data.map((destination) => (
        <ContinentItem key={destination.slug}>
          <Link href={`/destinos#${destination.slug}`}>
            <Text size="md">{destination.title}</Text>
            <MapContinentWrapper>
              <ContinentMapIcon continent={destination.slug} />
            </MapContinentWrapper>
          </Link>
        </ContinentItem>
      ))}
    </Element>
  );
};

export default FeatureDestination;
